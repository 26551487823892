<template>
    <div id="wrap">
        <div class="banner banner-style-1">
            <img class="banner-img" src="../assets/image/joinus.jpg" alt="banner">
            <div class="banner-content">
                <div class="container">
                    <h1 class="banner-title">加入我们</h1>
                </div>
            </div>
        </div>
        <div class="container wrap">
            <ol class="breadcrumb" vocab="https://schema.org/" typeof="BreadcrumbList">
                <li class="home" property="itemListElement" typeof="ListItem"><router-link to="/"><a href="../index.html"
                            property="item" typeof="WebPage"><span property="name"
                                class="hide">南开太阳</span>首页</a></router-link>
                    <meta property="position" content="1">
                </li>
                <li class="active" property="itemListElement" typeof="ListItem"><i class="wpcom-icon wi"><svg
                            aria-hidden="true">
                            <use xlink:href="#wi-arrow-right-3"></use>
                        </svg></i><router-link to="/JoinusView"><a href="" property="item" typeof="WebPage"><span
                                property="name">加入我们</span></a></router-link>
                    <meta property="position" content="2">
                </li>
            </ol>
            <div class="main main-full">
                <div class="entry">
                    <div class="entry-content">
                        <p class="has-text-align-center has-medium-font-size"><strong>热门职位</strong></p>
                        <el-collapse v-model="activeName" accordion>
                            <el-collapse-item title="UI设计师" name="1">
                                <p><strong>工作职责:</strong></p>
                                <p>1、负责公司软、硬件产品的UI设计工作，包括但不限于系统设计规范、系统工具应用设计、互联网应用设计等业务方向；</p>
                                <p>2、根据产品需求和数据变化，及时调整优化设计方案，主导设定产品的整体视觉风格并跟进开发落地产品，并且能够持续探索提升用户体验；</p>
                                <p>3、推动项目，把控设计产出，跟踪并还原设计成果，通过创新方案解决产品难题；</p>
                                <p>4、时刻监控分析流行产品设计趋势，研究目标用户审美倾向并优化现有产品。</p>
                                <p>5、具备一定的营销知识，熟练掌握新的营销渠道如短视频、图文等；</p>
                                <p><strong>职位要求：</strong></p>
                                <p>1、美术、设计或相关专业大专以上学历，有互联网移动或PC端设计经验；</p>
                                <p>2、善于捕捉流行设计语言趋势，归纳吸收能力强，有强大的自我更新迭代意识；</p>
                                <p>3、美术功底扎实、有审美洁癖，又兼具文字敏感，擅长沟通协作；</p>
                                <p>4、精通Photoshop、Illustrator、CorelDraw、CAD等设计软件；</p>
                                <p>5、富有工作激情、创新欲望和责任感，能承受高强度工作压力；</p>
                                <p>6、有3D、动效设计经验及手绘能力强者优先；简历需附带作品集。</p>
                            </el-collapse-item>
                            <el-collapse-item title="硬件工程师（实习生）" name="2">
                                <p><strong>工作内容：</strong></p>
                                <p>1、硬件开发能力</p>
                                <p>1）根据系统需求，进行硬件板卡或控制产品的设计和功能能测试,例如：硬件单板开发：画 PCB 板、电路图设计、器件选型、底软环路设计等；</p>
                                <p>2）负责电源类单板和测量检测类单板的方案设计及开发，包括方案设计、原理图设计、 PCB 布局、底软和环路设计。</p>
                                <p>3）有数电模电基础，精通开关电源拓扑、环路、功率器件等专业知识、熟悉 MIPI 、 USB 等接口协议和专业知识。熟悉 Linux 系统编程、 MCU 控制编程等。
                                </p>
                                <p>4）按照计划完成符合功能性能要求和质量标准的硬件产品；</p>
                                <p>5）根据产品详细设计报告，完成符合功能和性能要求的逻辑设计；</p>
                                <p>6）编写调试程序，测试或协助测试开发的硬件设备，确保其按设计要求正常运行；</p>
                                <p>7）编写项目文档、质量记录以及其他有关文档；</p>
                                <p>8）维护管理或协助管理所开发的硬件。</p>
                                <p>2、通用软件开发能力（加分项）</p>
                                <p>1）掌握 C ++/ Python / JS 一种或多种编程语言或精通 w eb 前端开发语言和框架，掌握常用数据结构、常用算法，了解软件工程、敏捷开发等知识；对 Linux
                                    系统或内核有一定的了解。</p>
                                <p>2）熟悉 modbus RTU/ TCP / IP 通信协议、 HTTP 协议，具备分析抓包的能力、 Linux 配置和 XShel
                                    使用；了解常用的软件架构模式、基本的编程编译工具。</p>
                                <p>3）有分布式集群、虚拟化技术、并行计算任务调度，资源管理、文件系统、存储、大数据等领域开发经验者优先；</p>
                                <p>4）对服务器方面的知识有一定的了解；</p>

                                <p><strong>任职要求：</strong></p>
                                <p>1、电子工程、自动化、机电一体化、计算机、物联网等相关专业，本科以上学历；</p>
                                <p>2、掌握电路设计理论知识，熟悉电路设计、 PCB 布板、电路调试，至少熟练掌握一种EDA、PCB软件设计工具（Altium Designer，Cadence,PADS等）；
                                </p>
                                <p>3、具有一定的设备调试能力。</p>
                                <p>4、掌握常用的硬件设计工具，调试仪器仪表的使用方法；</p>
                                <p>5、熟悉嵌入式系统的硬件及软件开发,有数据采集、信号处理、系统设计经验；</p>
                                <p>6、工作态度积极，责任心强，良好的团队精神；</p>
                                <p>7、做事认真、细心、负责，能够专心学习技术；</p>
                            </el-collapse-item>
                            <el-collapse-item title="后端工程师" name="3">
                                <p><strong>岗位职责：</strong></p>
                                <p>1.协助系统的总体技术框架的规划与设计，能提出自己的意见和想法；</p>
                                <p>2.分析业务需求，为软件开发和运行提供架构设计、模型设计，对架构业务合理性、可扩展性做出支持；</p>
                                <p>3.配合核心功能的架构与开发，维护系统公用的中间件。</p>
                                <p><strong>任职资格：</strong></p>
                                <p>1.计算机、通信等相关专业；</p>
                                <p>2.熟悉MySQL数据库和后端数据设计；</p>
                                <p>3.熟悉SSM、Spring boot，了解springcloud等主流后端框架；</p>
                                <p>4.熟练掌握java；</p>
                                <p>5.了解多线程开发以及原理,缓存以及消息队列技术的运用；</p>
                                <p>6.了解HTML5，http等web技术。</p>
                            </el-collapse-item>
                            <el-collapse-item title="前端工程师" name="4">
                                <p><strong>任职资格：</strong></p>
                                <p>1. 3年以上开发经验，有互联网webapp开发经验者优先考虑；</p>
                                <p>2. 熟悉XHTML /HTML5/CSS3，能够编写符合W3C标准、兼容主流浏览器的代码；</p>
                                <p>3. 精通JavaScript开发,能够熟练的使用JavaScript进行面向对象编程；</p>
                                <p>4. 能够独立结合后端api接口以及设计稿完成页面构成；</p>
                                <p>5. 熟练掌握一种或多种前端UI框架，如JqueryUI/Bootetrap/EasyUI等；</p>
                                <p>6. 熟悉Ajax、Json及Xml等数据交换格式；</p>
                                <p>7. 有响应式页面开发经验，能使用HTML5开发Mobile APP 界面和体验效果的应用，能开发兼容移动版浏览器的应用，并且能实现各种交互效果；</p>
                                <p>8. 至少了解一门后端程序语言（php，java等）；</p>
                                <p>9. 具有良好的沟通能力，善于沟通和逻辑表达，良好的团队合作精神和积极主动的沟通意识，具有较强的抗压能力。</p>
                            </el-collapse-item>
                        </el-collapse>
                        <!-- <p class="wp-block-wpcom-accordion panel-group"
                            id="accordion-c620d5dc-8e39-4fae-a294-303164b091db">
                            <p class="wp-block-wpcom-acco panel panel-default">
                                <p class="panel-heading" id="heading-c620d5dc-8e39-4fae-a294-303164b091db-0">
                                    <h4 class="panel-title"><a role="button" data-toggle="collapse"
                                            data-parent="#accordion-c620d5dc-8e39-4fae-a294-303164b091db"
                                            href="#accordion-c620d5dc-8e39-4fae-a294-303164b091db-0">前端开发</a></h4>
                                </p>
                                <div id="accordion-c620d5dc-8e39-4fae-a294-303164b091db-0"
                                    class="panel-collapse collapse in">
                                    <div class="panel-body">
                                        <p><strong>岗位职责：</strong></p>
                                        <p>1.计算机、通信等相关专业；</p>
                                        <p>2.协助公司前端产品主的开发、维护、迭代、技术支持工作；</p>
                                        <p>3.参与项目、产品需求分析与设计，协助PC和手机上Web前端架构设计；</p>
                                        <p>4.配合后端完成接口调试、独立开发Web模块以及相关业务需求。</p>
                                        <p><strong>任职资格：</strong></p>
                                        <p>1.熟悉JavaScript、HTML5、CSS等前端技术，可熟练使用Vue、React等常见框架；</p>
                                        <p>2.可熟练使用nodejs, webpack，了解浏览器，熟练使用主流前端开发工具；</p>
                                        <p>3.有微信小程序、公众号开发经验者优先；</p>
                                        <p>4.踏实认真稳重、沟通能力良好、善于学习新技术、责任心强。</p>
                                    </div>
                                </div>
                            </p>
                            <div class="wp-block-wpcom-acco panel panel-default">
                                <div class="panel-heading" id="heading-c620d5dc-8e39-4fae-a294-303164b091db-1">
                                    <h4 class="panel-title"><a role="button" data-toggle="collapse"
                                            data-parent="#accordion-c620d5dc-8e39-4fae-a294-303164b091db"
                                            href="#accordion-c620d5dc-8e39-4fae-a294-303164b091db-1">后端开发</a></h4>
                                </div>
                                <div id="accordion-c620d5dc-8e39-4fae-a294-303164b091db-1" class="panel-collapse collapse">
                                    <div class="panel-body">
                                        <p><strong>岗位职责：</strong></p>
                                        <p>1.协助系统的总体技术框架的规划与设计，能提出自己的意见和想法；</p>
                                        <p>2.分析业务需求，为软件开发和运行提供架构设计、模型设计，对架构业务合理性、可扩展性做出支持；</p>
                                        <p>3.配合核心功能的架构与开发，维护系统公用的中间件。</p>
                                        <p><strong>任职资格：</strong></p>
                                        <p>1.计算机、通信等相关专业；</p>
                                        <p>2.熟悉MySQL数据库和后端数据设计；</p>
                                        <p>3.熟悉SSM、Spring boot，了解springcloud等主流后端框架；</p>
                                        <p>4.熟练掌握java；</p>
                                        <p>5.了解多线程开发以及原理,缓存以及消息队列技术的运用；</p>
                                        <p>6.了解HTML5，http等web技术。</p>
                                    </div>
                                </div>
                            </div>



                            <div class="wp-block-wpcom-acco panel panel-default">
                                <div class="panel-heading" id="heading-c620d5dc-8e39-4fae-a294-303164b091db-2">
                                    <h4 class="panel-title"><a role="button" data-toggle="collapse"
                                            data-parent="#accordion-c620d5dc-8e39-4fae-a294-303164b091db"
                                            href="#accordion-c620d5dc-8e39-4fae-a294-303164b091db-2">APP开发</a></h4>
                                </div>
                                <div id="accordion-c620d5dc-8e39-4fae-a294-303164b091db-2" class="panel-collapse collapse">
                                    <div class="panel-body">
                                        <p><strong>岗位职责：</strong></p>



                                        <p>1.负责Andriod和iOS端的APP开发</p>



                                        <p>2.配合移动终端的需求分析、设计、开发、测试等工作。</p>



                                        <p><strong>任职资格：</strong></p>



                                        <p>1.计算机、通信等相关专业；</p>



                                        <p>2.熟悉Android/IOS开发平台及框架原理；</p>



                                        <p>3.熟悉网络编程，熟悉TCP/IP、HTTP等网络协议；</p>



                                        <p>4.熟悉Javascript、Dart、HTML/HTML5/XML、CSS/CSS3等前端开发技术；</p>



                                        <p>5.熟练使用flutter，taro，react-native，uniapp等主流跨平台app开发框架；</p>



                                        <p>6.责任心强，具备良好的沟通能力和优秀的团队协作能力。</p>
                                    </div>
                                </div>
                            </div>
                            <div class="wp-block-wpcom-acco panel panel-default">
                                <div class="panel-heading" id="heading-c620d5dc-8e39-4fae-a294-303164b091db-3">
                                    <h4 class="panel-title"><a role="button" data-toggle="collapse"
                                            data-parent="#accordion-c620d5dc-8e39-4fae-a294-303164b091db"
                                            href="#accordion-c620d5dc-8e39-4fae-a294-303164b091db-3">产品设计</a></h4>
                                </div>
                                <div id="accordion-c620d5dc-8e39-4fae-a294-303164b091db-3" class="panel-collapse collapse">
                                    <div class="panel-body">
                                        <p><strong>岗位职责：</strong></p>



                                        <p>1.负责物联网相关硬件产品的规划、设计，实现从产品概念策划、设计到推动实施产品及后续跟踪优化和数据运营工作，持续提升用户的使用体验；</p>



                                        <p>2. 协助进行产品创新、规划，配合业务方撰写解决方案；</p>



                                        <p>3.产品生命周期管理，推动和跟踪产品的研发过程；</p>



                                        <p>4.制定产品方案，竞品分析，产品原型图及商业模式等。</p>



                                        <p><strong>任职资格：</strong></p>



                                        <p>1.了解工业互联网，工业自动化，智慧城市、工业物联网、人工智能、大数据云平台、电子政务等行业；</p>



                                        <p>2.具备嵌入式，工业互联网基本技术储备，有工业产品或工业互联网产品的规划与设计思路，在校期间有实操经验者优先；</p>



                                        <p>3.有良好的沟通能力和协调能力，拥有强烈的责任心和团队合作精神，善于思考和总结；</p>


                                        <p>4.充满激情，主动性和学习能力强，能承受较强的工作压力；</p>
                                        <p>5.有良好的产品策划、设计能力，熟悉产品管理的基础方法论知识。</p>
                                    </div>
                                </div>
                            </div>
                            <div class="wp-block-wpcom-acco panel panel-default">
                                <div class="panel-heading" id="heading-c620d5dc-8e39-4fae-a294-303164b091db-4">
                                    <h4 class="panel-title"><a role="button" data-toggle="collapse"
                                            data-parent="#accordion-c620d5dc-8e39-4fae-a294-303164b091db"
                                            href="#accordion-c620d5dc-8e39-4fae-a294-303164b091db-4">JAVA开发实习生</a></h4>
                                </div>
                                <div id="accordion-c620d5dc-8e39-4fae-a294-303164b091db-4" class="panel-collapse collapse">
                                    <div class="panel-body">
                                        <p><strong>岗位职责:</strong><br>1.基于功能要求，进行java服务端后台业务逻辑、外部接口调用等功能开发；</p>
                                        <p>2.根据开发进度和任务分配，完成相应模块软件的设计、开发、编程任务;</p>
                                        <p>3.能够独立的按产品需求进行技术方案设计和编码实现，确保安全、可扩展性、质量和性能。</p>
                                        <p><strong>任职资格:</strong><br>1.2023年毕业，实习周期6个月以上，本科学历；<br>2.Java基础扎实，对于Java高级特性有一定了解；<br>3.精通Spring以及相关组件，有SpringCloud使用经验优先；<br>4.熟悉mysgl数据库，具备标准SQL的编写能力；<br>5.加分项:具备一种或者多种脚本编写能力，至少是shell；<br>6.加分项:了解web客户端原生开发的技能或具备一定经验。
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="wp-block-wpcom-acco panel panel-default">
                                <div class="panel-heading" id="heading-c620d5dc-8e39-4fae-a294-303164b091db-5">
                                    <h4 class="panel-title"><a role="button" data-toggle="collapse"
                                            data-parent="#accordion-c620d5dc-8e39-4fae-a294-303164b091db"
                                            href="#accordion-c620d5dc-8e39-4fae-a294-303164b091db-5">新媒体运营实习生</a></h4>
                                </div>
                                <div id="accordion-c620d5dc-8e39-4fae-a294-303164b091db-5" class="panel-collapse collapse">
                                    <div class="panel-body">
                                        <p><strong>岗位职责</strong>：<br>1.负责公司网站及新媒体矩阵的内容编辑、更新及日常运营管理，包括但不限于微信公众号、抖音、社群等平台;<br>2.捕捉行业热点话题，高效产出文字、视频内容，提升用户关注度;<br>3
                                            策划和落地社会化传播创意方案，对创新性内容有敏感度和创作能力，扩大各个渠道的影响力;<br>4.懂得短视频拍摄、剪辑等;<br>5.定向策划和执行各类粉丝推广计划，了解各新媒体平台特性、人群和使用习惯，以数据为导向优化内容。
                                        </p>



                                        <p><strong>任职资格：</strong><br>1.2023年应届毕业生，实习周期6个月以上，本科以上学历，媒体、新闻、广告、市场营销类相关专业；<br>2.能独立完成新媒体平台的内容策划、文案撰写;<br>3.知识面广，有较好的文字功底，能编写脚本、策划直播及视频内容;<br>4.审美好，善于沟通、执行力强，热爱创作。
                                        </p>
                                    </div>
                                </div>
                            </div>
                        <div class="wp-block-wpcom-acco panel panel-default">
                            <div class="panel-heading" id="heading-c620d5dc-8e39-4fae-a294-303164b091db-6">
                                <h4 class="panel-title"><a role="button" data-toggle="collapse"
                                        data-parent="#accordion-c620d5dc-8e39-4fae-a294-303164b091db"
                                        href="#accordion-c620d5dc-8e39-4fae-a294-303164b091db-6">产品助理实习生</a></h4>
                            </div>
                            <div id="accordion-c620d5dc-8e39-4fae-a294-303164b091db-6" class="panel-collapse collapse">
                                <div class="panel-body">
                                    <p><strong>岗位职责:</strong><br>1.协助产品经理进行用户需求调研，竞品分析和市场分析，提升产品竞争力;<br>2.进行产品前期规划，确定产品功能、流程、用户体验以及后<br>台解决方案，协助绘制产品原型图，编写相关需求文档;<br>3.日常进度跟踪以确保产品功能特性和交互符合产品需求文档的要求;<br>4.协助完成产品的迭代更新工作，统计分析产品运营数据，收集用户意见，改善用户体验。
                                    </p>



                                    <p><strong>任职资格:</strong><br>1.计算机或相关专业2023年应届毕业生，实习周期6个月以上，本科学历;<br>2.了解产品实现过程，包括市场分析、需求分析、产品功能设计、业务流程设计、界面设计、用户研究和可用性测试等;<br>3.具有较强的信息收集与数据分析能力，能够通过多种方式对<br>市场和竞品进行分析比对;<br>4.较好的文档编写能力，能够使用offce办公软件，visio、ps、ae、axure、xmind等日常工作;<br>5.良好的沟通能力、推动能力、统筹协调能力、自我驱动力、抗压能力，拥有强烈的责任心和团队合作精神。
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div> -->
                        <p class="has-text-align-center has-medium-font-size"><strong>公司简介</strong></p>
                        <blockquote class="wp-block-quote">
                            <p>预见科技是一家面向工业企业生产领域的人工智能+物联网产品解决方案公司
                                “Foresee A.I.”、“Super Hammer++”两大框架是我们的核心竞争力，也是支撑“用人工智能技术让生产更安全、智能、高效”使命的关键
                                &nbsp;
                            </p>
                            <p>我们专注于用AIoT能力解决工业企业不同空间场景下的安全生产和管理问题
                                我们正在为这些客户提供包括算法、硬件、软件、应用平台、解决方案在内的一站式产品和服务

                            </p>
                        </blockquote>
                        <p class="has-text-align-center has-medium-font-size"><strong>简历投递</strong></p>
                        <div class="is-layout-flow wp-block-group">
                            <div class="wp-block-group__inner-container">
                                <p><strong>联系我们：</strong></p>
                                <p>电话：18526406875 （孙先生）&nbsp;</p>
                                <p>邮箱：Hr@will-tec.com </p>
                                <p>地址：天津市滨海新区动漫中路创展大厦-4F&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</p>
                            </div>
                        </div>
                        <!-- <p><strong>在线投递简历</strong></p> -->
                        <!-- <div class="wpforms-container wpforms-container-full" id="wpforms-1826">
                            <form id="wpforms-form-1826" class="wpforms-validate wpforms-form wpforms-ajax-form"
                                data-formid="1826" method="post" enctype="multipart/form-data"
                                action="/join-us/?wpforms_form_id=1826" data-token="59aa3d843146d4d0fc4592578f5d904a">
                                <noscript class="wpforms-error-noscript">请在浏览器中启用JavaScript来完成此表单。</noscript>
                                <div class="wpforms-field-container">
                                    <div id="wpforms-1826-field_1-container" class="wpforms-field wpforms-field-name"
                                        data-field-id="1"><label class="wpforms-field-label" for="wpforms-1826-field_1">姓名
                                            <span class="wpforms-required-label">*</span></label><input type="text"
                                            id="wpforms-1826-field_1" class="wpforms-field-medium wpforms-field-required"
                                            name="wpforms[fields][1]" required=""></div>
                                    <div id="wpforms-1826-field_2-container" class="wpforms-field wpforms-field-phone"
                                        data-field-id="2"><label class="wpforms-field-label" for="wpforms-1826-field_2">手机
                                            <span class="wpforms-required-label">*</span></label><input type="tel"
                                            id="wpforms-1826-field_2"
                                            class="wpforms-field-medium wpforms-field-required wpforms-smart-phone-field"
                                            data-rule-smart-phone-field="true" name="wpforms[fields][2]" required=""></div>
                                    <div id="wpforms-1826-field_3-container" class="wpforms-field wpforms-field-email"
                                        data-field-id="3"><label class="wpforms-field-label" for="wpforms-1826-field_3">电邮
                                            <span class="wpforms-required-label">*</span></label><input type="email"
                                            id="wpforms-1826-field_3" class="wpforms-field-medium wpforms-field-required"
                                            name="wpforms[fields][3]" required=""></div>
                                    <div id="wpforms-1826-field_5-container"
                                        class="wpforms-field wpforms-field-select wpforms-field-select-style-modern"
                                        data-field-id="5"><label class="wpforms-field-label" for="wpforms-1826-field_5">应聘职位
                                            <span class="wpforms-required-label">*</span></label><select
                                            id="wpforms-1826-field_5"
                                            class="wpforms-field-medium wpforms-field-required choicesjs-select"
                                            data-size-class="wpforms-field-row wpforms-field-medium" data-search-enabled="1"
                                            name="wpforms[fields][5]" required="required">
                                            <option value="" class="placeholder" disabled="" selected='selected'>前端开发
                                            </option>
                                            <option value="前端开发">前端开发</option>
                                            <option value="后端开发">后端开发</option>
                                            <option value="APP开发">APP开发</option>
                                            <option value="JAVA后端开发">JAVA后端开发</option>
                                            <option value="产品设计">产品设计</option>
                                            <option value="JAVA开发实习生">JAVA开发实习生</option>
                                            <option value="产品助理实习生">产品助理实习生</option>
                                            <option value="新媒体运营实习生">新媒体运营实习生</option>
                                        </select></div>
                                    <div id="wpforms-1826-field_4-container" class="wpforms-field wpforms-field-file-upload"
                                        data-field-id="4"><label class="wpforms-field-label" for="wpforms-1826-field_4">简历附件
                                            <span class="wpforms-required-label">*</span></label>
                                        <div class="wpforms-uploader" data-field-id="4" data-form-id="1826"
                                            data-input-name="wpforms_1826_4" data-extensions="pdf" data-max-size="2097152"
                                            data-max-file-number="1" data-post-max-size="8388608"
                                            data-max-parallel-uploads="4" data-parallel-uploads="true"
                                            data-file-chunk-size="2097152">
                                            <div class="dz-message">
                                                <svg viewbox="0 0 1024 1024" focusable="false" data-icon="inbox"
                                                    width="50px" height="50px" fill="#b1b1b1" aria-hidden="true">
                                                    <path
                                                        d="M885.2 446.3l-.2-.8-112.2-285.1c-5-16.1-19.9-27.2-36.8-27.2H281.2c-17 0-32.1 11.3-36.9 27.6L139.4 443l-.3.7-.2.8c-1.3 4.9-1.7 9.9-1 14.8-.1 1.6-.2 3.2-.2 4.8V830a60.9 60.9 0 0 0 60.8 60.8h627.2c33.5 0 60.8-27.3 60.9-60.8V464.1c0-1.3 0-2.6-.1-3.7.4-4.9 0-9.6-1.3-14.1zm-295.8-43l-.3 15.7c-.8 44.9-31.8 75.1-77.1 75.1-22.1 0-41.1-7.1-54.8-20.6S436 441.2 435.6 419l-.3-15.7H229.5L309 210h399.2l81.7 193.3H589.4zm-375 76.8h157.3c24.3 57.1 76 90.8 140.4 90.8 33.7 0 65-9.4 90.3-27.2 22.2-15.6 39.5-37.4 50.7-63.6h156.5V814H214.4V480.1z">
                                                    </path>
                                                </svg>
                                                <span class="modern-title">Click or drag a file to this area to
                                                    upload.</span>

                                            </div>
                                        </div>
                                        <input type="text" autocomplete="off" class="dropzone-input"
                                            style="position:absolute!important;clip:rect(0,0,0,0)!important;height:1px!important;width:1px!important;border:0!important;overflow:hidden!important;padding:0!important;margin:0!important;"
                                            id="wpforms-1826-field_4" name="wpforms_1826_4" required="" value="">
                                        <div class="wpforms-field-description">仅支持PDF格式文件。</div>
                                    </div>
                                    <div id="wpforms-1826-field_6-container" class="wpforms-field wpforms-field-textarea"
                                        data-field-id="6"><label class="wpforms-field-label"
                                            for="wpforms-1826-field_6">其他说明</label><textarea id="wpforms-1826-field_6"
                                            class="wpforms-field-medium" name="wpforms[fields][6]"
                                            placeholder="有话想对HR说……"></textarea></div>
                                </div>
                                <div class="wpforms-submit-container"><input type="hidden" name="wpforms[id]"
                                        value="1826"><input type="hidden" name="wpforms[author]" value="1"><input
                                        type="hidden" name="wpforms[post_id]" value="1808"><button type="submit"
                                        name="wpforms[submit]" id="wpforms-submit-1826" class="wpforms-submit"
                                        data-alt-text="发送中…" data-submit-text="立即投递" aria-live="assertive"
                                        value="wpforms-submit">立即投递</button><noscript><img
                                            src="../assets/picture/submit-spin.svg" class="wpforms-submit-spinner"
                                            style="display: none;" width="26" height="26" alt="加载"></noscript><img
                                        src="../assets/picture/submit-spin.svg"
                                        data-original="https://www.nkty.com/wordpress/wp-content/plugins/wpforms/assets/images/submit-spin.svg"
                                        class="wpforms-submit-spinner j-lazy" style="display: none;" width="26" height="26"
                                        alt="加载"></div>
                            </form>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
  export default {
    data() {
      return {
        activeName: '1'
      };
    }
  }
</script>
<style></style>