<template>
  <div id="app">
    <header id="header" class="header" style="position: fixed;background-color:white">
      <div class="container clearfix">
        <div class="navbar-header">
          <h1 class="logo">
            <a href rel="home">
              <img src="./assets/picture/gray.png" style="width:170px" />
            </a>
          </h1>
          <button type="button" class="navbar-toggle collapsed" data-toggle="collapse" data-target=".navbar-menu">
            <span class="icon-bar icon-bar-1"></span>
            <span class="icon-bar icon-bar-2"></span>
            <span class="icon-bar icon-bar-3"></span>
          </button>
        </div>
        <nav class="collapse navbar-collapse navbar-right navbar-menu">
          <ul id="menu-menu_home" class="nav navbar-nav main-menu wpcom-adv-menu">
            <li class="menu-item page-item-961 active">
              <a href><strong>首页</strong></a>
            </li>

            <li class="menu-item dropdown">
              <router-link to="/software"><a href="software/index.html" class="dropdown-toggle "><strong>软件产品</strong></a></router-link>
              <ul class="dropdown-menu menu-item-wrap menu-item-col-3 ">
                <li class="menu-item">
                <router-link
                      to="/ProductionExperience">未来生产体验</router-link>
                </li>
                <li class="menu-item">
                  <router-link to="/OfficeExperience">未来办公体验</router-link>
                </li>
                <!-- <li class="menu-item">
                 <router-link to="/CustomizedSolution">AIoT定制方案</router-link>
                </li> -->
              </ul>
            </li>
            <li class="menu-item">
              <router-link to="/HardView"><a href="hardware/index.html"><strong>智能硬件</strong></a></router-link>
            </li>
            <li class="menu-item menu-item-style menu-item-style1 dropdown">
              <router-link to="/SolutionView"><a href="solution/index.html" class="dropdown-toggle"><strong>解决方案</strong></a></router-link>
              <ul class="dropdown-menu menu-item-wrap menu-item-col-3">
                <!-- <li class="menu-item dropdown">
                  <a href="#">AIoT定制方案</a>
                  <ul class="dropdown-menu">
                    
                    <li class="menu-item">
                      <router-link to="/AlgorithmCustomization">AI视觉识别算法定制<br>（雾、烟火、船只）</router-link>
                    </li> 
                    <li class="menu-item">
                      <router-link to="/IoThardwareDocking">IOT物联网硬件对接与定制方案</router-link>
                    </li>
                    <li class="menu-item">
                      <router-link to="/DevelopmentSerrvices">应用软件定制开发服务</router-link>
                    </li>
                  </ul>
                </li> -->
                <li class="menu-item dropdown">
                  <a href="#">未来生产体验</a>
                  <ul class="dropdown-menu">
                    <li class="menu-item">
                      <router-link to="/EchicleManagement">公务车辆管理系统</router-link>
                    </li>
                    <li class="menu-item">
                      <router-link to="/VoiceDispatching">语音调度系统</router-link>
                    </li>
                    <li class="menu-item">
                      <router-link to="/IntelligentLamp">智能灯控系统</router-link>
                    </li>
                    <!-- <li class="menu-item">
                      <router-link to="/DistributionRoom">无人配电间系统</router-link>
                    </li> -->
                    <!-- <li class="menu-item">
                      <router-link to="/EnvironmentalonMitoring">环境监测系统</router-link>
                    </li> -->
                    <li class="menu-item">
                      <router-link to="/SmartCommunity">智慧景区票务管理系统</router-link>
                    </li>
                  </ul>
                </li>

                <li class="menu-item dropdown">
                  <a href="#">未来办公体验</a>
                  <ul class="dropdown-menu">
                    <li class="menu-item">
                      <router-link to="/OfficePlatform">“IM-F”智能办公平台</router-link>
                    </li>
                    <li class="menu-item">
                      <router-link to="/DocumentManagement">“百阅”文件管理系统</router-link>
                    </li>
                    <!-- <li class="menu-item">
                      <router-link to="/HumanCompensation">人力薪酬系统</router-link>
                    </li>
                    <li class="menu-item">
                      <router-link to="/IntelligentConference">智能会议室系统</router-link>
                    </li>
                    <li class="menu-item">
                      <router-link to="/LowcodeForm">低代码表单系统</router-link>
                    </li>
                    <li class="menu-item">
                      <router-link to="/LargedataScreen">数据大屏系统</router-link>
                    </li> -->
                  </ul>
                </li>

              </ul>
            </li>
            <li class="menu-item">
              <router-link to="/server"><a href="service/index.html"><strong>技术服务</strong></a></router-link>
            </li>
            <li class="menu-item">
              <router-link to="/SupportView"><a href="support/index.html"><strong>客户支持</strong></a></router-link>
            </li>

            <li class="menu-item dropdown" style="text-align: center;">
              <router-link to="/company"><a href="about/index.html" class="dropdown-toggle"><strong>关于我们</strong></a></router-link>
              <ul class="dropdown-menu menu-item-wrap menu-item-col-2" style="text-align: center;">
                <!-- <li class="menu-item">
                  <router-link to="/company">
                    <a href="about/index.html">公司简介</a>
                  </router-link>
                </li> -->
                <!-- <li class="menu-item">
                  <router-link to="/introduce">
                    <a href="about/team/index.html">团队介绍</a>
                  </router-link>
                </li> -->
                <li class="menu-item">
                  <router-link to="/NewsView">新闻中心</router-link>
                </li>
                <li class="menu-item">
                  <router-link to="/ContactView">联系我们</router-link>
                </li>
                <li class="menu-item">
                  <router-link to="/JoinusView">加入我们</router-link>
                </li>
              </ul>
            </li>

          </ul>

          <div class="navbar-action pull-right">
            <div class="search-index pull-left">
              <a class="search-icon" href="javascript:;">
                <i class="wpcom-icon wi">
                  <svg aria-hidden="true">
                    <use xlink:href="#wi-search" />
                  </svg>
                </i>
              </a>
              <form class="search-form" action="https://www.nkty.com" method="get" role="search">
                <input type="text" class="keyword" name="s" placeholder="输入关键词搜索..." value />
                <button type="submit" class="submit">
                  <i class="wpcom-icon wi">
                    <svg aria-hidden="true">
                      <use xlink:href="#wi-search" />
                    </svg>
                  </i>
                </button>
              </form>
            </div>
          </div>
        </nav>
      </div>
    </header>
    <router-view></router-view>

    <footer class="footer width-footer-bar">
      <div class="container">
        <div class="footer-widget row hidden-xs">
          <div id="nav_menu-3" class="col-md-4 col-sm-8 hidden-xs widget widget_nav_menu">
            <h4 class="widget-title">关于</h4>
            <div class="menu-footer_about-container">
              <ul id="menu-footer_about" class="menu">
                <li id="menu-item-1399" class="menu-item menu-item-1399"><a href="about/index.html">关于我们</a></li>
                <li id="menu-item-1400" class="menu-item menu-item-1400"><a href="support/index.html">客户支持</a></li>
                <li id="menu-item-1401" class="menu-item menu-item-1401"><a href="contact/index.html">联系我们</a></li>
              </ul>
            </div>
          </div>
          <div id="nav_menu-4" class="col-md-4 col-sm-8 hidden-xs widget widget_nav_menu">
            <h4 class="widget-title">行业方案</h4>
            <div class="menu-footer_it-container">
              <ul id="menu-footer_it" class="menu">
                <li id="menu-item-1605" class="menu-item menu-item-1605"> <router-link to="/EchicleManagement">公务车辆管理系统</router-link></li>
                <!-- <li id="menu-item-1606" class="menu-item menu-item-1606"><a href="service/index.html">生产车辆调度系统</a></li> -->
                <!-- <li id="menu-item-1607" class="menu-item menu-item-1607"><a href="service/index.html">人员定位&巡检系统</a></li> -->
                <li id="menu-item-1614" class="menu-item menu-item-1614"><router-link to="/VoiceDispatching">语音调度系统</router-link></li>
                <li id="menu-item-1615" class="menu-item menu-item-1615"> <router-link to="/IntelligentLamp">智能灯控系统</router-link></li>
                <li id="menu-item-1615" class="menu-item menu-item-1615"> <router-link to="/SmartCommunity">智慧景区票务管理系统</router-link></li>
                <!-- <li id="menu-item-1616" class="menu-item menu-item-1616"><a href="service/index.html">无人配电间系统</a></li> -->
                <!-- <li id="menu-item-1617" class="menu-item menu-item-1617"><a href="service/index.html">环境监测系统</a></li> -->


              </ul>
            </div>
          </div>
          <div id="nav_menu-5" class="col-md-4 col-sm-8 hidden-xs widget widget_nav_menu">
            <h4 class="widget-title"></h4>
            <div class="menu-footer_hangye-container">
              <ul id="menu-footer_hangye" class="menu">
                <li id="menu-item-1581" class="menu-item menu-item-1581" style="margin-top: 47px;">  <router-link to="/OfficePlatform">“IM-F”智能办公平台</router-link>
                </li>
                <li id="menu-item-1582" class="menu-item menu-item-1582">    <router-link to="/DocumentManagement">“百阅”文件管理系统</router-link>
                </li>
                <!-- <li id="menu-item-1583" class="menu-item menu-item-1583"><a href="solution/gongye/index.html">生产排班系统</a> -->
                <!-- </li> -->
                <!-- <li id="menu-item-1584" class="menu-item menu-item-1584"><a href="solution/xiaoyuan/index.html">人力薪酬系统</a>
                </li> -->
                <!-- <li id="menu-item-1585" class="menu-item menu-item-1585"><a href="solution/shequ/index.html">智能会议室系统</a>
                </li> -->
                <!-- <li id="menu-item-1586" class="menu-item menu-item-1586"><a
                    href="solution/nengyuan/index.html">低代码表单系统</a>
                </li> -->
                <!-- <li id="menu-item-1617" class="menu-item menu-item-1617"><a href="service/index.html">环境监测系统</a></li> -->
              </ul>
            </div>
          </div>
          <div id="media_image-2" class="col-md-4 col-sm-8 hidden-xs widget widget_media_image">
            <h4 class="widget-title">关注</h4><img width="150" height="150" src="./assets/image/two code.png"
              class="image wp-image-1618  attachment-thumbnail size-thumbnail j-lazy" alt="Home" decoding="async"
              style="max-width: 100%; height: auto;"
              data-original="https://www.nkty.com/wordpress/wp-content/uploads/2022/03/qrcode_for_nkty-150x150.jpg">
          </div>
          <div class="col-md-6 col-md-offset-2 col-sm-16 col-xs-24 widget widget_contact">
            <h3 class="widget-title">服务热线</h3>
            <div class="widget-contact-wrap">
              <div class="widget-contact-tel">022-85194616</div>
              <div class="widget-contact-time">客服服务时段：周一至周五，8:30 - 17:30，节假日休息</div>
              <a class="contact-btn" href="contact/index1.html">
                联系解决方案专家 </a>
              <div class="widget-contact-sns">
              </div>
            </div>
          </div>
        </div>
        <div class="copyright">
          <p style="text-align: center;">COPYRIGHT © 预见（天津）智能科技有限公司 | <a href="javascript:;" target="_blank"
              rel="nofollow noopener">津ICP备2021003041号-1</a></p>
          <p style="text-align: center;">Add：天津市滨海新区中新生态城动漫中路创展大厦B座-4F</p>
        </div>
      </div>
    </footer>
  </div>
</template>
<script>
export default {
 
}
</script>
<style lang="less" scoped>
@import url(./assets/style/reset.css);

</style>
