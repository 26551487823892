import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'//首页
import ServerView from '../views/ServerView.vue'//技术服务
import SoftwareView from '../views/SoftwareView.vue'//软件产品
import ProductionExperience from '../views/SoftwareView/ProductionExperience.vue'//未来生产体验
import OfficeExperience from '../views/SoftwareView/OfficeExperience.vue'//未来办公体验
import CustomizedSolution from '../views/SoftwareView/CustomizedSolution.vue'//AIot定制方案
import AboutView from '../views/AboutView.vue'//关于我们
import ContactView from '../views/ContactView.vue'//联系我们
import HardView from '../views/HardView.vue'//智能硬件
import JoinusView from '../views/JoinusView.vue'//加入我们
import NewsView from '../views/NewsView.vue'//新闻中心
import SolutionView from '../views/SolutionView.vue'//解决方案
import SupportView from '../views/SupportView.vue'//技术支持
import CustomizeView from '@/views/HomeView/CustomizeView.vue'//主页AIot定制方案
import OfficeView from '@/views/HomeView/OfficeView.vue'//主页的办公体验
import ProduceView from '@/views/HomeView/ProduceView.vue'//主页的生产体验
import CarameView from '../views/HardView/Carame.vue'//AI防爆摄像头
import EcoboxView from '../views/HardView/Ecobox.vue'//ECO-BoX工控机、
import LightView from '../views/HardView/Lightbox.vue'//灯控盒子
import CompanyView from '../views/CompanyView.vue'//公司简介
import IntroduceView from '../views/Introduce.vue'//团队介绍
import AlgorithmCustomization from '../views/SolutionView/AlgorithmCustomization.vue'//AIoT定制方案/AI视觉识别算法定制（雾、烟火、船只）
import ApplicationManagement from '../views/SolutionView/ApplicationManagement.vue'//AIoT定制方案/“Foresee OS”应用管理系统
import DevelopmentSerrvices from '../views/SolutionView/DevelopmentServices.vue'//AIoT定制方案/应用软件定制开发服务
import IoThardwareDocking from '../views/SolutionView/IoThardwareDocking.vue'//AIoT定制方案/AIOT物联网硬件对接与定制方案
import EchicleManagement from '../views/SolutionView/EchicleManagement.vue'//未来生产体验/公务车辆管理系统
import VehicleScheduling from '../views/SolutionView/VehicleScheduling.vue'//未来生产体验/生产车辆调度系统
import PersonnelPositioning from '../views/SolutionView/PersonnelPositioning.vue'//未来生产体验/人员定位&巡检系统
import VoiceDispatching from '../views/SolutionView/VoiceDispatching.vue'//未来生产体验/语音调度系统
import IntelligentLamp from '../views/SolutionView/Intelligentlamp.vue'//未来生产体验/智能灯控系统
import DistributionRoom  from '../views/SolutionView/DistributionRoom.vue'//未来生产体验/无人配电间系统
import EnvironmentalonMitoring from '../views/SolutionView/EnvironmentalonMitoring.vue'//未来生产体验/环境监测系统
import SmartCommunity from '../views/SolutionView/SmartCommunity.vue'//未来生产体验/智慧社区系统
import OfficePlatform from '../views/SolutionView/OfficePlatform.vue'//未来办公体验/“IM-F”智能办公平台
import DocumentManagement from '../views/SolutionView/DocumentManagement.vue'//未来办公体验/“百阅”文件管理系统
import ProductionScheduling from '../views/SolutionView/ProductionScheduling.vue'//未来办公体验/ 生产排班系统
import HumanCompensation from '../views/SolutionView/HumanCompensation.vue'//未来办公体验/人力薪酬系统
import IntelligentConference from '../views/SolutionView/IntelligentConference.vue'//未来办公体验/智能会议室系统
import LowcodeForm from '../views/SolutionView/LowcodeForm.vue'//未来办公体验/低代码表单系统
import LargedataScreen from '../views/SolutionView/LargedataScreen.vue'//未来办公体验/数据大屏系统
import Aqckfbzsd from '../views/HardView/Aqckfbzsd.vue'//安全出口防爆指示灯
import Fbd from '../views/HardView/Fbd.vue'//防爆灯1
import Fbdgnyjd from '../views/HardView/Fbdgnyjd.vue'//防爆多功能应急灯
import Fbledd from '../views/HardView/Fbledd.vue'//防爆灯2
import Fbzmd from '../views/HardView/Fbzmd.vue'//防爆照明灯
import Gkzafbxhd from '../views/HardView/Gkzafbxhd.vue'//高空障碍防爆信号灯
import Ld from '../views/HardView/Ld.vue'//路灯
import Nrffbledd from '../views/HardView/Nrffbledd.vue'//NRF防爆LED灯
import Dgpyjlythsxy from '../views/HardView/Dgpyjlythsxy.vue'//多光谱远距离一体化摄影仪
import Fbbkq from '../views/HardView/Fbbkq.vue'//防爆布控球
import Fbbqxsxy from '../views/HardView/Fbbqxsxy.vue'//防爆半球形摄影仪
import Fbhwrcxy from '../views/HardView/Fbhwrcxy.vue'//防爆红外热成像仪
import Fbyt from '../views/HardView/Fbyt.vue'//防爆云台
import Fbznsbqxsxy from '../views/HardView/Fbznsbqxsxy.vue'//防爆智能识别球形摄影仪
import Fbznsbtxsxy from '../views/HardView/Fbznsbtxsxy.vue'//防爆智能识别筒形摄影仪
import Fbznsbythsxy from '../views/HardView/Fbznsbythsxy.vue'//防爆智能识别一体化摄影仪
import Tcy from '../views/HardView/Tcy.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/Tcy',
    name: 'Tcy',
    component: Tcy
  },
  {
    path: '/Dgpyjlythsxy',
    name: 'Dgpyjlythsxy',
    component: Dgpyjlythsxy
  },
  {
    path: '/Fbbkq',
    name: 'Fbbkq',
    component: Fbbkq
  },
  {
    path: '/Fbbqxsxy',
    name: 'Fbbqxsxy',
    component: Fbbqxsxy
  },
  {
    path: '/Fbhwrcxy',
    name: 'Fbhwrcxy',
    component:Fbhwrcxy
  },
  {
    path: '/Fbyt',
    name: 'Fbyt',
    component: Fbyt
  },
  {
    path: '/Fbznsbqxsxy',
    name: 'Fbznsbqxsxy',
    component: Fbznsbqxsxy
  },
  {
    path: '/Fbznsbtxsxy',
    name: 'Fbznsbtxsxy',
    component: Fbznsbtxsxy
  },
  {
    path: '/Fbznsbythsxy',
    name: 'Fbznsbythsxy',
    component: Fbznsbythsxy
  },
  {
    path: '/Aqckfbzsd',
    name: 'Aqckfbzsd',
    component: Aqckfbzsd
  },
  {
    path: '/Fbd',
    name: 'Fbd',
    component: Fbd
  },
  {
    path: '/Fbdgnyjd',
    name: 'Fbdgnyjd',
    component: Fbdgnyjd
  },
  {
    path: '/Fbledd',
    name: 'Fbledd',
    component: Fbledd
  },
  {
    path: '/Fbzmd',
    name: 'Fbzmd',
    component: Fbzmd
  },
  {
    path: '/Gkzafbxhd',
    name: 'Gkzafbxhd',
    component: Gkzafbxhd
  },
  {
    path: '/Ld',
    name: 'Ld',
    component: Ld
  },
  {
    path: '/Nrffbledd',
    name: 'Nrffbledd',
    component: Nrffbledd
  },
  {
    path: '/IntelligentConference',
    name: 'IntelligentConference',
    component: IntelligentConference
  },
  {
    path: '/LowcodeForm',
    name: 'LowcodeForm',
    component: LowcodeForm
  },
  {
    path: '/LargedataScreen',
    name: 'LargedataScreen',
    component: LargedataScreen
  },
  {
    path: '/OfficePlatform',
    name: 'OfficePlatform',
    component: OfficePlatform
  },
  {
    path: '/DocumentManagement',
    name: 'DocumentManagement',
    component: DocumentManagement
  },
  {
    path: '/ProductionScheduling',
    name: 'ProductionScheduling',
    component: ProductionScheduling
  },
  {
    path: '/HumanCompensation',
    name: 'HumanCompensation',
    component: HumanCompensation
  },
  {
    path: '/EchicleManagement',
    name: 'EchicleManagement',
    component: EchicleManagement
  },
  {
    path: '/VehicleScheduling',
    name: 'VehicleScheduling',
    component: VehicleScheduling
  },
  {
    path: '/PersonnelPositioning',
    name: 'PersonnelPositioning',
    component: PersonnelPositioning
  },
  {
    path: '/VoiceDispatching',
    name: 'VoiceDispatching',
    component: VoiceDispatching
  },
  {
    path: '/IntelligentLamp',
    name: 'IntelligentLamp',
    component: IntelligentLamp
  },
  {
    path: '/DistributionRoom',
    name: 'DistributionRoom',
    component: DistributionRoom
  },
  {
    path: '/EnvironmentalonMitoring',
    name: 'EnvironmentalonMitoring',
    component: EnvironmentalonMitoring
  },
  {
    path: '/SmartCommunity',
    name: 'SmartCommunity',
    component: SmartCommunity
  },
  {
    path: '/AlgorithmCustomization',
    name: 'AlgorithmCustomization',
    component: AlgorithmCustomization
  },
  {
    path: '/ApplicationManagement',
    name: 'ApplicationManagement',
    component: ApplicationManagement
  },
  {
    path: '/DevelopmentSerrvices',
    name: 'DevelopmentSerrvices',
    component: DevelopmentSerrvices
  },
  {
    path: '/IoThardwareDocking',
    name: 'IoThardwareDocking',
    component: IoThardwareDocking
  },
  {
    path: '/company',
    name: 'company',
    component: CompanyView
  },
  {
    path: '/introduce',
    name: 'introduce',
    component:IntroduceView
  },
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/server',
    name: 'server',
    component:ServerView
  },
  {
    path: '/software',
    name: 'software',
    component:SoftwareView,
  },
  {
    path:'/ProductionExperience',
    name:'ProductionExperience',
    component:ProductionExperience,
 },{
  path:'/OfficeExperience',
    name:'OfficeExperience',
    component:OfficeExperience,
 },
 {
  path:'/CustomizedSolution',
    name:'CustomizedSolution',
    component:CustomizedSolution,
 },
 {
  path:'/AboutView',
    name:'AboutView',
    component:AboutView,
 },
 {
  path:'/ContactView',
    name:'ContactView',
    component:ContactView,
 },
 {
  path:'/HardView',
    name:'HardView',
    component:HardView,
 },
 {
  path:'/JoinusView',
    name:'JoinusView',
    component:JoinusView,
 },
 {
  path:'/NewsView',
    name:'NewsView',
    component:NewsView,
 },
 {
  path:'/SolutionView',
    name:'SolutionView',
    component:SolutionView,
 },
 {
  path:'/SupportView',
    name:'SupportView',
    component:SupportView,
 },{
  path: '/produceview',
  name: 'produceview',
  component: ProduceView
 },
 {
  path: '/officeview',
  name: 'officeview',
  component: OfficeView
 },
 {
  path: '/customizeview',
  name: 'customizeview',
  component: CustomizeView
 },{
  path: '/carameview',
  name: 'carameview',
  component: CarameView
 },
 {
  path: '/ecoboxview',
  name: 'ecoboxview',
  component: EcoboxView
 },
 {
  path: '/lightview',
  name: 'lightview',
  component: LightView
 },
 
 
]

const router = new VueRouter({
  routes
})

export default router
