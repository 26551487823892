<template>
    <div id="wrap" style="margin: 0px;padding: 0;">
        <section class="section wpcom-modules modules-swiper swiper-container swiper-full" id="modules-16">
            <div class="swiper-wrapper">
                <div class="swiper-slide" style="height:auto;">
                    <div class="slide-img" style="background-image: url('../assets/image/smart-4168483_1920.jpg');">
                        <video class="slide-video" muted="" autoplay="" loop="" playsinline="" preload="auto"
                            src="../assets/image/1111.webm" type="video/webm"
                            poster="../assets/image/smart-4168483_1920.jpg" style="width:100%;">
                        </video>
                        <!-- <video width="320" height="240" controls autoplay>
                            <source src="../assets/image/V1.webm" type="video/webm">
                            </video> -->
                        <div class="slide-content shadow-1">
                            <div class="slide-content-inner container align-center">
                                <p style="text-align: center;"><strong><span style="font-size: 70px;">AIoT for
                                            Decision</span></strong><br>
                                    <!-- <strong><span style="font-size: 42px;">技术驱动 价值务实</span></strong><br> -->
                                    <strong><span style="font-size: 30px;">运用人工智能和物联网技术解决管理决策问题</span></strong>
                                </p>
                                <p style="text-align: center;"><strong><span
                                            style="font-size: 30px;">工业生产领域AIoT探索者和实践者</span></strong></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section>
            <h1 style="display: flex;align-items: center;justify-content: center;margin-top: 60px;">
                <div style="display:inline-block;margin-right: 20px;"><img src="../assets/image/image9.png" alt=""
                        style="width: 60px;height: 45px;"></div>
                <span style="font-weight: 1000;">行业现状</span>

                <div style="display:inline-block;margin-left: 20px;"><img src="../assets/image/image10.png" alt=""
                        style="width: 60px;height: 45px;"></div>
            </h1>
            <p style="text-align: center; margin: 20px 0px 5px;font-size: 20px;">价值引领&emsp;&emsp; 技术驱动&emsp;&emsp; 敦本务实</p>
            <div style="margin:40px 0px">
                <div class="boxxx">
                    <div class="bigbox">
                        <div style="text-align: center;"><img src="../assets/image/image12.png" alt=""
                                style="width: 74px;height: 74px;"></div>
                        <h1 style="font-size: 21px;font-weight: 1000;">行业高危 事故频发</h1>
                        <div style="background: linear-gradient(90deg, rgb(144, 237, 215) 0%, rgb(1, 110, 164) 100%);    width: 44px;
                            height: 4px;
                            border-radius: 4px;
                            margin: 10px auto"></div>
                        <p>工厂环境恶劣，有毒有害气体众多，人员行为不可控，缺少可视化监测工具</p>
                        <div class="dwjz">
                            <button
                                style="background-color: #47535e;color:white;padding: 4px 30px;font-size: 14px;border-radius: 2px;margin-bottom: 20px;">
                                <span>现状一
                                </span>
                            </button>
                        </div>
                    </div>
                    <div class="bigbox">
                        <div style="text-align: center;"><img src="../assets/image/image13.png" alt=""
                                style="width: 74px;height: 74px;"></div>
                        <h1 style="font-size: 21px;font-weight: 1000;">资产复杂 运维困难
                        </h1>
                        <div style="background: linear-gradient(90deg, rgb(144, 237, 215) 0%, rgb(1, 110, 164) 100%);    width: 44px;
                            height: 4px;
                            border-radius: 4px;
                            margin: 10px auto"></div>
                        <p>现场设备众多，管线密集，运行状态和性能难监测，仓库物资寻找困难
                        </p>
                        <div class="dwjz">
                            <button
                                style="background-color: #47535e;color:white;padding: 4px 30px;font-size: 14px;border-radius: 2px;margin-bottom: 20px;">
                                <span>现状二
                                </span>
                            </button>
                        </div>
                    </div>
                    <div class="bigbox">
                        <div style="text-align: center;"><img src="../assets/image/image14.png" alt=""
                                style="width: 74px;height: 74px;"></div>
                        <h1 style="font-size: 21px;font-weight: 1000;">告警分散 管理困难</h1>
                        <div style="background: linear-gradient(90deg, rgb(144, 237, 215) 0%, rgb(1, 110, 164) 100%);    width: 44px;
                            height: 4px;
                            border-radius: 4px;
                            margin: 10px auto"></div>
                        <p>企业各系统告警信息分散，无效告警众多，缺少告警联动机制和分析工具
                        </p>
                        <div class="dwjz">
                            <button
                                style="background-color: #47535e;color:white;padding: 4px 30px;font-size: 14px;border-radius: 2px;margin-bottom: 20px;">
                                <span>现状三
                                </span>
                            </button>
                        </div>
                    </div>
                    <div class="bigbox">
                        <div style="text-align: center;"><img src="../assets/picture/image6.png" alt=""
                                style="width: 74px;height: 74px;"></div>
                        <h1 style="font-size: 21px;font-weight: 1000;">信息孤岛 数据分散</h1>
                        <div style="background: linear-gradient(90deg, rgb(144, 237, 215) 0%, rgb(1, 110, 164) 100%);    width: 44px;
                            height: 4px;
                            border-radius: 4px;
                            margin: 10px auto"></div>
                        <p>企业生产经营管理系统众多，相互独立，数据孤岛，缺少统一的协同工作和企业运营平台，与业务融合不足，缺乏跨系统联动分析
                        </p>
                        <div class="dwjz">
                            <button
                                style="background-color: #47535e;color:white;padding: 4px 30px;font-size: 14px;border-radius: 2px;margin-bottom: 20px;">
                                <span>现状四
                                </span>
                            </button>
                        </div>
                    </div>
                    <div class="bigbox">
                        <div style="text-align: center;"><img src="../assets/image/image15.png" alt=""
                                style="width: 74px;height: 74px;"></div>
                        <h1 style="font-size: 21px;font-weight: 1000;">缺乏联动 效率低下
                        </h1>
                        <div style="background: linear-gradient(90deg, rgb(144, 237, 215) 0%, rgb(1, 110, 164) 100%);    width: 44px;
                            height: 4px;
                            border-radius: 4px;
                            margin: 10px auto"></div>
                        <p>各业务部门、管理系统，形成“部门墙、工具墙”导致日常管理和应急指挥流程复杂，协调成本高

                        </p>
                        <div class="dwjz">
                            <button
                                style="background-color: #47535e;color:white;padding: 4px 30px;font-size: 14px;border-radius: 2px;margin-bottom: 20px;">
                                <span>现状五
                                </span>
                            </button>
                        </div>
                    </div>
                </div>

            </div>



        </section>

        <section class="section wpcom-modules modules-fullwidth" id="modules-13" style="background-color: #47535e;">
            <div class="j-modules-inner container">
                <section class="section wpcom-modules modules-desc" id="modules-14">
                    <div class="sec-title sec-title-2">
                        <div class="sec-title-wrap">
                            <h2 style="color:white">核心技术</h2>
                            <span style="color:white">Core Technology</span>
                        </div>
                    </div>
                    <div class="desc-inner desc-center">
                        <p>预见科技是一家面向工业生产制造领域企业的人工智能+物联网技术解决方案公司</br>
                            “Foresee A.I.”“Super Hammer++”两大核心技术框架，是支撑“运用人工智能和物联网技术解决管理决策问题”使命的关键</br>
                            我们专注于用AIoT能力解决工业企业不同空间场景下的数据感知和管理决策问题</br>
                            我们正在为这些客户提供包括算法、硬件、软件、应用平台、解决方案在内的一站式产品和服务</p>
                    </div>
                </section>
                <section class="section wpcom-modules modules-color-gird" id="modules-15">
                    <ul class="cg-list cg-list-border cg-layout-4 cg-type-1">
                        <li class="cg-item cg-item-4 cg-i-0">
                            <div class="cg-item-inner">
                                <div class="flex-left">
                                    <div class="cg-item-text">
                                        <h3 class="cg-title">软件</h3>
                                    </div>
                                    <div class="cg-item-text">
                                        <p class="cg-desc">Software</p>
                                    </div>
                                </div><i class="wpcom-icon ri-global-line cg-fa"></i>
                            </div>
                        </li>
                        <li class="cg-item cg-item-4 cg-i-1">
                            <div class="cg-item-inner">
                                <div class="flex-left">
                                    <div class="cg-item-text">
                                        <h3 class="cg-title">硬件</h3>
                                    </div>
                                    <div class="cg-item-text">
                                        <p class="cg-desc">
                                            Hardware</p>
                                    </div>
                                </div><i class="wpcom-icon ri-cloud-line cg-fa"></i>
                            </div>
                        </li>
                        <li class="cg-item cg-item-4 cg-i-2">
                            <div class="cg-item-inner">
                                <div class="flex-left">
                                    <div class="cg-item-text">
                                        <h3 class="cg-title">算法</h3>
                                    </div>
                                    <div class="cg-item-text">
                                        <p class="cg-desc">Algorithm</p>
                                    </div>
                                </div><i class="wpcom-icon ri-database-2-line cg-fa"></i>
                            </div>
                        </li>
                        <li class="cg-item cg-item-4 cg-i-3">
                            <div class="cg-item-inner">
                                <div class="flex-left">
                                    <div class="cg-item-text">
                                        <h3 class="cg-title">应用平台</h3>
                                    </div>
                                    <div class="cg-item-text">
                                        <p class="cg-desc">Application platform</p>
                                    </div>
                                </div><i class="wpcom-icon ri-bubble-chart-line cg-fa"></i>
                            </div>
                        </li>
                    </ul>
                </section>
            </div>
        </section>
        <section class="section wpcom-modules modules-image-gird container" id="modules-5">
            <div class="sec-title sec-title-2">
                <div class="sec-title-wrap">
                    <h2>解决方案</h2>
                </div>
            </div>
            <ul class="ig-list text-center">
                <li class="ig-item ig-item-3">
                    <router-link to="/produceview">
                        <a class="ig-item-inner" href="solution/xiaoyuan/index.html"> <img class="j-lazy"
                                src="../assets/picture/shengchantiyan.png" alt="智慧校园">
                            <div class="ig-item-text">
                                <h3 style="margin:29px">未来生产体验</h3>
                                <p></p>
                            </div>
                        </a>
                    </router-link>
                </li>
                <li class="ig-item ig-item-3">
                    <router-link to="/officeview">
                        <a class="ig-item-inner" href="solution/nongye/index.html"> <img class="j-lazy"
                                src="../assets/picture/home_bangong.jpg" alt="智慧农业">
                            <div class="ig-item-text">
                                <h3 class="ig-title">未来办公体验</h3>
                            </div>
                        </a>
                    </router-link>
                </li>
                <li class="ig-item ig-item-3">
                    <router-link to="/customizeview">
                        <a class="ig-item-inner" href="solution/nengyuan/index.html"> <img class="j-lazy"
                                src="../assets/picture/AIOT.png" alt="智慧能源">
                            <div class="ig-item-text">
                                <h3 class="ig-title">AIoT定制方案</h3>
                            </div>
                        </a>
                    </router-link>
                </li>
                <li class="ig-item ig-item-3">
                    <router-link to="/customizeview">
                        <a class="ig-item-inner" href="solution/nengyuan/index.html"> <img class="j-lazy"
                                src="../assets/picture/zhinnegfangbao.png" alt="智慧能源">
                            <div class="ig-item-text">
                                <h3 class="ig-title">防爆智能硬件</h3>
                            </div>
                        </a>
                    </router-link>
                </li>
            </ul>
        </section>
        <section class="section wpcom-modules modules-button container" id="modules-9">

            <div class="modules-button-inner">
                <a class="btn btn-xl btn-primary btn-0" href="solution/index.html">
                    更多行业方案 </a>
            </div>
        </section>
        <section class="section wpcom-modules modules-fullwidth" id="modules-10">
            <div class="j-modules-inner container">
                <section class="section wpcom-modules modules-my-module" id="modules-8">
                    <section class="section wpcom-modules modules-process" id="modules-8_13">
                        <div class="sec-title sec-title-2">
                            <div class="sec-title-wrap">
                                <h2>合作流程</h2>
                                <span>联系预见人工智能解决方案专家，探讨您的项目。</span>
                            </div>
                        </div>
                        <img src="../assets/picture/hezuoliucheng.png" alt="">
                        <!-- <ul class="process prcs-0">
                            <li class="prcs-item prcs-item-0">
                                <i class="wpcom-icon ri-number-1 prcs-icon"></i>
                                <div class="prcs-content">
                                    <i class="prcs-dot"></i>
                                    <h3 class="prcs-title">提交合作咨询</h3>
                                    <p class="prcs-desc">提交您的合作意向, 描述项目概况</p>
                                </div>
                            </li>
                            <li class="prcs-item prcs-item-1">
                                <i class="wpcom-icon ri-number-2 prcs-icon"></i>
                                <div class="prcs-content">
                                    <i class="prcs-dot"></i>
                                    <h3 class="prcs-title">初步沟通</h3>
                                    <p class="prcs-desc">我们的商务会与您沟通合作点</p>
                                </div>
                            </li>
                            <li class="prcs-item prcs-item-2">
                                <i class="wpcom-icon ri-number-3 prcs-icon"></i>
                                <div class="prcs-content">
                                    <i class="prcs-dot"></i>
                                    <h3 class="prcs-title">正式合作</h3>
                                    <p class="prcs-desc">提炼需求, 定制项目, 签订合同并验证方案</p>
                                </div>
                            </li>
                            <li class="prcs-item prcs-item-3">
                                <i class="wpcom-icon ri-number-4 prcs-icon"></i>
                                <div class="prcs-content">
                                    <i class="prcs-dot"></i>
                                    <h3 class="prcs-title">交付验收</h3>
                                    <p class="prcs-desc">正式交付, 并给予培训与长期支持</p>
                                </div>
                            </li>
                        </ul> -->
                    </section>
                </section>
            </div>
        </section>
        <section class="section wpcom-modules modules-my-module" id="modules-7">
            <section class="section wpcom-modules modules-fullwidth" id="modules-7_3">
                <div class="j-modules-inner container">
                    <section class="section wpcom-modules modules-desc" id="modules-7_4">
                        <div class="sec-title sec-title-2">
                            <div class="sec-title-wrap">
                                <h2 style="color:white">在线提交您的项目需求 预约预见科技售前工程师一对一服务</h2>
                                <span style="color:white">1小时内极速响应 7x24小时为您服务</span>
                            </div>
                        </div>
                        <div class="desc-inner desc-center">
                            <p></p>
                        </div>
                        <div class="module-more desc-center">
                            <a class="btn btn-round btn-light more-link" href="contact/index1.html">
                                立即提交<i class="wpcom-icon wi"><svg aria-hidden="true">
                                        <use xlink:href="#wi-arrow-right"></use>
                                    </svg></i> </a>
                        </div>
                    </section>
                </div>
            </section>
        </section>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped >
.el-col-lg-4-8 {
    width: 20%;
}


.dwjz {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translate(-50%, 0);
}

@media only screen and (min-width: 600px) {
    .boxxx {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 0px 30px;
    }

    .bigbox {
        text-align: center;
        position: relative;
        width: 250px;
        height: 360px;
        padding: 22px 30px;
        background: #fff;
        box-shadow: 0 10px 30px rgba(0, 0, 0, .16);
        border-radius: 10px;
    }
}

@media only screen and (max-width: 600px) {
    .boxxx {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 0 20px;
    }

    .bigbox {
        text-align: center;
        position: relative;
        width: 90%;
        height: 320px;
        padding: 22px 30px;
        background: #fff;
        box-shadow: 0 10px 30px rgba(0, 0, 0, .16);
        border-radius: 10px;
        margin: 10px auto;
    }
}


.Title_title__eS-Aw {
    margin: 4px 20px 0;
    font-weight: 700;
    font-size: 34px
}

.Home_main__2AqCK .Home_solution__2pYyC {
    margin-top: 60px;
}

.animate__backInLeft {
    animation-name: backInLeft;
}

.animate__animated {
    animation-duration: 1s;
    animation-duration: var(--animate-duration);
    animation-fill-mode: both;
}

.animate__animated {
    --animate-duration: 1.5s;
}

.Title_center__36XUp {
    display: flex;
    align-items: center;
    justify-content: center;
}

.Title_center__36XUp {
    display: flex;
    align-items: center;
    justify-content: center;
}

.Title_center__36XUp {
    display: flex;
    align-items: center;
    justify-content: center;
}

.Title_center__36XUp {
    display: flex;
    align-items: center;
    justify-content: center;
}

.Title_center__36XUp {
    display: flex;
    align-items: center;
    justify-content: center;
}

.Title_center__36XUp {
    display: flex;
    align-items: center;
    justify-content: center;
}

.Title_center__36XUp {
    display: flex;
    align-items: center;
    justify-content: center;
}

.Title_center__36XUp {
    display: flex;
    align-items: center;
    justify-content: center;
}

.Home_main__2AqCK .Home_solution__2pYyC .Home_content__netV5 {
    margin: 0 auto 40px;
}

/* @media (min-width: 1600px) */

.Home_main__2AqCK .Home_solution__2pYyC .Home_content__netV5,
.Home_visio__3xljO .Home_customer__3S1Rr {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 1fr;
    justify-items: center;
    grid-row-gap: 40px;
}

/* @media (min-width: 1600px) */

.Home_wrapper__3Gv1v {
    max-width: 1320px;
}

.Home_main__2AqCK .Home_solution__2pYyC .Home_content__netV5,
.Home_visio__3xljO .Home_customer__3S1Rr {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    justify-items: center;
    grid-row-gap: 40px
}

.SolutionCard_box__2Ln8m {
    position: relative;
    width: 276px;
    height: 360px;
    padding: 22px 42px;
    background: #fff;
    -webkit-box-shadow: 0 10px 30px rgba(0, 0, 0, .16);
    box-shadow: 0 10px 30px rgba(0, 0, 0, .16);
    border-radius: 10px
}

.SolutionCard_textCenter__3bigM {
    text-align: center
}
</style>
<style type="text/css" id="wp-custom-css">
/** WPFORMS BUTTON COLOR **/
.wpforms-form button {
    background-color: #cb241c !important;
}

/** WPFORMS SUBMIT BUTTON **/
button.wpforms-submit,
button.wpforms-submit:hover {
    color: #FFFFFF !important;
    background-color: #cb241c !important;
    border: 1px solid #ff6a00 !important;
    padding: 10px 20px !important;
    border-radius: 30px !important;
}

@media screen and (max-width: 991px) {

    .ig-item-3,
    .modules-feature .fea-item,
    .modules-feature .cols-2 .fea-item {
        width: 100%;
    }
}

.slide-video {
    min-width: 100%;
    min-height: 100%;
}
</style>
<style id='global-styles-inline-css' type='text/css'>
body {
    --wp--preset--color--black: #000000;
    --wp--preset--color--cyan-bluish-gray: #abb8c3;
    --wp--preset--color--white: #ffffff;
    --wp--preset--color--pale-pink: #f78da7;
    --wp--preset--color--vivid-red: #cf2e2e;
    --wp--preset--color--luminous-vivid-orange: #ff6900;
    --wp--preset--color--luminous-vivid-amber: #fcb900;
    --wp--preset--color--light-green-cyan: #7bdcb5;
    --wp--preset--color--vivid-green-cyan: #00d084;
    --wp--preset--color--pale-cyan-blue: #8ed1fc;
    --wp--preset--color--vivid-cyan-blue: #0693e3;
    --wp--preset--color--vivid-purple: #9b51e0;
    --wp--preset--gradient--vivid-cyan-blue-to-vivid-purple: linear-gradient(135deg, rgba(6, 147, 227, 1) 0%, rgb(155, 81, 224) 100%);
    --wp--preset--gradient--light-green-cyan-to-vivid-green-cyan: linear-gradient(135deg, rgb(122, 220, 180) 0%, rgb(0, 208, 130) 100%);
    --wp--preset--gradient--luminous-vivid-amber-to-luminous-vivid-orange: linear-gradient(135deg, rgba(252, 185, 0, 1) 0%, rgba(255, 105, 0, 1) 100%);
    --wp--preset--gradient--luminous-vivid-orange-to-vivid-red: linear-gradient(135deg, rgba(255, 105, 0, 1) 0%, rgb(207, 46, 46) 100%);
    --wp--preset--gradient--very-light-gray-to-cyan-bluish-gray: linear-gradient(135deg, rgb(238, 238, 238) 0%, rgb(169, 184, 195) 100%);
    --wp--preset--gradient--cool-to-warm-spectrum: linear-gradient(135deg, rgb(74, 234, 220) 0%, rgb(151, 120, 209) 20%, rgb(207, 42, 186) 40%, rgb(238, 44, 130) 60%, rgb(251, 105, 98) 80%, rgb(254, 248, 76) 100%);
    --wp--preset--gradient--blush-light-purple: linear-gradient(135deg, rgb(255, 206, 236) 0%, rgb(152, 150, 240) 100%);
    --wp--preset--gradient--blush-bordeaux: linear-gradient(135deg, rgb(254, 205, 165) 0%, rgb(254, 45, 45) 50%, rgb(107, 0, 62) 100%);
    --wp--preset--gradient--luminous-dusk: linear-gradient(135deg, rgb(255, 203, 112) 0%, rgb(199, 81, 192) 50%, rgb(65, 88, 208) 100%);
    --wp--preset--gradient--pale-ocean: linear-gradient(135deg, rgb(255, 245, 203) 0%, rgb(182, 227, 212) 50%, rgb(51, 167, 181) 100%);
    --wp--preset--gradient--electric-grass: linear-gradient(135deg, rgb(202, 248, 128) 0%, rgb(113, 206, 126) 100%);
    --wp--preset--gradient--midnight: linear-gradient(135deg, rgb(2, 3, 129) 0%, rgb(40, 116, 252) 100%);
    --wp--preset--duotone--dark-grayscale: url('https://www.nkty.com/');
    --wp--preset--duotone--grayscale: url('https://www.nkty.com/');
    --wp--preset--duotone--purple-yellow: url('https://www.nkty.com/');
    --wp--preset--duotone--blue-red: url('https://www.nkty.com/');
    --wp--preset--duotone--midnight: url('https://www.nkty.com/');
    --wp--preset--duotone--magenta-yellow: url('https://www.nkty.com/');
    --wp--preset--duotone--purple-green: url('https://www.nkty.com/');
    --wp--preset--duotone--blue-orange: url('https://www.nkty.com/');
    --wp--preset--font-size--small: 13px;
    --wp--preset--font-size--medium: 20px;
    --wp--preset--font-size--large: 36px;
    --wp--preset--font-size--x-large: 42px;
    --wp--preset--spacing--20: 0.44rem;
    --wp--preset--spacing--30: 0.67rem;
    --wp--preset--spacing--40: 1rem;
    --wp--preset--spacing--50: 1.5rem;
    --wp--preset--spacing--60: 2.25rem;
    --wp--preset--spacing--70: 3.38rem;
    --wp--preset--spacing--80: 5.06rem;
}

:where(.is-layout-flex) {
    gap: 0.5em;
}

body .is-layout-flow>.alignleft {
    float: left;
    margin-inline-start: 0;
    margin-inline-end: 2em;
}

body .is-layout-flow>.alignright {
    float: right;
    margin-inline-start: 2em;
    margin-inline-end: 0;
}

body .is-layout-flow>.aligncenter {
    margin-left: auto !important;
    margin-right: auto !important;
}

body .is-layout-constrained>.alignleft {
    float: left;
    margin-inline-start: 0;
    margin-inline-end: 2em;
}

body .is-layout-constrained>.alignright {
    float: right;
    margin-inline-start: 2em;
    margin-inline-end: 0;
}

body .is-layout-constrained>.aligncenter {
    margin-left: auto !important;
    margin-right: auto !important;
}

body .is-layout-constrained> :where(:not(.alignleft):not(.alignright):not(.alignfull)) {
    max-width: var(--wp--style--global--content-size);
    margin-left: auto !important;
    margin-right: auto !important;
}

body .is-layout-constrained>.alignwide {
    max-width: var(--wp--style--global--wide-size);
}

body .is-layout-flex {
    display: flex;
}

body .is-layout-flex {
    flex-wrap: wrap;
    align-items: center;
}

body .is-layout-flex>* {
    margin: 0;
}

:where(.wp-block-columns.is-layout-flex) {
    gap: 2em;
}

.has-black-color {
    color: var(--wp--preset--color--black) !important;
}

.has-cyan-bluish-gray-color {
    color: var(--wp--preset--color--cyan-bluish-gray) !important;
}

.has-white-color {
    color: var(--wp--preset--color--white) !important;
}

.has-pale-pink-color {
    color: var(--wp--preset--color--pale-pink) !important;
}

.has-vivid-red-color {
    color: var(--wp--preset--color--vivid-red) !important;
}

.has-luminous-vivid-orange-color {
    color: var(--wp--preset--color--luminous-vivid-orange) !important;
}

.has-luminous-vivid-amber-color {
    color: var(--wp--preset--color--luminous-vivid-amber) !important;
}

.has-light-green-cyan-color {
    color: var(--wp--preset--color--light-green-cyan) !important;
}

.has-vivid-green-cyan-color {
    color: var(--wp--preset--color--vivid-green-cyan) !important;
}

.has-pale-cyan-blue-color {
    color: var(--wp--preset--color--pale-cyan-blue) !important;
}

.has-vivid-cyan-blue-color {
    color: var(--wp--preset--color--vivid-cyan-blue) !important;
}

.has-vivid-purple-color {
    color: var(--wp--preset--color--vivid-purple) !important;
}

.has-black-background-color {
    background-color: var(--wp--preset--color--black) !important;
}

.has-cyan-bluish-gray-background-color {
    background-color: var(--wp--preset--color--cyan-bluish-gray) !important;
}

.has-white-background-color {
    background-color: var(--wp--preset--color--white) !important;
}

.has-pale-pink-background-color {
    background-color: var(--wp--preset--color--pale-pink) !important;
}

.has-vivid-red-background-color {
    background-color: var(--wp--preset--color--vivid-red) !important;
}

.has-luminous-vivid-orange-background-color {
    background-color: var(--wp--preset--color--luminous-vivid-orange) !important;
}

.has-luminous-vivid-amber-background-color {
    background-color: var(--wp--preset--color--luminous-vivid-amber) !important;
}

.has-light-green-cyan-background-color {
    background-color: var(--wp--preset--color--light-green-cyan) !important;
}

.has-vivid-green-cyan-background-color {
    background-color: var(--wp--preset--color--vivid-green-cyan) !important;
}

.has-pale-cyan-blue-background-color {
    background-color: var(--wp--preset--color--pale-cyan-blue) !important;
}

.has-vivid-cyan-blue-background-color {
    background-color: var(--wp--preset--color--vivid-cyan-blue) !important;
}

.has-vivid-purple-background-color {
    background-color: var(--wp--preset--color--vivid-purple) !important;
}

.has-black-border-color {
    border-color: var(--wp--preset--color--black) !important;
}

.has-cyan-bluish-gray-border-color {
    border-color: var(--wp--preset--color--cyan-bluish-gray) !important;
}

.has-white-border-color {
    border-color: var(--wp--preset--color--white) !important;
}

.has-pale-pink-border-color {
    border-color: var(--wp--preset--color--pale-pink) !important;
}

.has-vivid-red-border-color {
    border-color: var(--wp--preset--color--vivid-red) !important;
}

.has-luminous-vivid-orange-border-color {
    border-color: var(--wp--preset--color--luminous-vivid-orange) !important;
}

.has-luminous-vivid-amber-border-color {
    border-color: var(--wp--preset--color--luminous-vivid-amber) !important;
}

.has-light-green-cyan-border-color {
    border-color: var(--wp--preset--color--light-green-cyan) !important;
}

.has-vivid-green-cyan-border-color {
    border-color: var(--wp--preset--color--vivid-green-cyan) !important;
}

.has-pale-cyan-blue-border-color {
    border-color: var(--wp--preset--color--pale-cyan-blue) !important;
}

.has-vivid-cyan-blue-border-color {
    border-color: var(--wp--preset--color--vivid-cyan-blue) !important;
}

.has-vivid-purple-border-color {
    border-color: var(--wp--preset--color--vivid-purple) !important;
}

.has-vivid-cyan-blue-to-vivid-purple-gradient-background {
    background: var(--wp--preset--gradient--vivid-cyan-blue-to-vivid-purple) !important;
}

.has-light-green-cyan-to-vivid-green-cyan-gradient-background {
    background: var(--wp--preset--gradient--light-green-cyan-to-vivid-green-cyan) !important;
}

.has-luminous-vivid-amber-to-luminous-vivid-orange-gradient-background {
    background: var(--wp--preset--gradient--luminous-vivid-amber-to-luminous-vivid-orange) !important;
}

.has-luminous-vivid-orange-to-vivid-red-gradient-background {
    background: var(--wp--preset--gradient--luminous-vivid-orange-to-vivid-red) !important;
}

.has-very-light-gray-to-cyan-bluish-gray-gradient-background {
    background: var(--wp--preset--gradient--very-light-gray-to-cyan-bluish-gray) !important;
}

.has-cool-to-warm-spectrum-gradient-background {
    background: var(--wp--preset--gradient--cool-to-warm-spectrum) !important;
}

.has-blush-light-purple-gradient-background {
    background: var(--wp--preset--gradient--blush-light-purple) !important;
}

.has-blush-bordeaux-gradient-background {
    background: var(--wp--preset--gradient--blush-bordeaux) !important;
}

.has-luminous-dusk-gradient-background {
    background: var(--wp--preset--gradient--luminous-dusk) !important;
}

.has-pale-ocean-gradient-background {
    background: var(--wp--preset--gradient--pale-ocean) !important;
}

.has-electric-grass-gradient-background {
    background: var(--wp--preset--gradient--electric-grass) !important;
}

.has-midnight-gradient-background {
    background: var(--wp--preset--gradient--midnight) !important;
}

.has-small-font-size {
    font-size: var(--wp--preset--font-size--small) !important;
}

.has-medium-font-size {
    font-size: var(--wp--preset--font-size--medium) !important;
}

.has-large-font-size {
    font-size: var(--wp--preset--font-size--large) !important;
}

.has-x-large-font-size {
    font-size: var(--wp--preset--font-size--x-large) !important;
}

.wp-block-navigation a:where(:not(.wp-element-button)) {
    color: inherit;
}

:where(.wp-block-columns.is-layout-flex) {
    gap: 2em;
}

.wp-block-pullquote {
    font-size: 1.5em;
    line-height: 1.6;
}
</style>