import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
Vue.use(ElementUI,{ size: 'mini'})
import 'element-ui/lib/theme-chalk/index.css'
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: function (h) { return h(App) }
}).$mount('#app')

import '../src/assets/css/choices.min.css'
import '../src/assets/css/classic-themes.min.css'
import '../src/assets/css/dropzone.min.css'
import '../src/assets/css/intl-tel-input.min.css'
import '../src/assets/css/remixicon-2.5.0.min.css'
import '../src/assets/css/smartideo.css'
import '../src/assets/css/style.5.10.0.1669847362.css'
import '../src/assets/css/style.min.css'
import '../src/assets/css/style.p1263.5.10.0.1669847362.css'
import '../src/assets/css/style.p1353.5.10.0.1669847362.css'
import '../src/assets/css/style.p1355.5.10.0.1669847362.css'
import '../src/assets/css/style.p1357.5.10.0.1669847362.css'
import '../src/assets/css/style.p1361.5.10.0.1669847362.css'
import '../src/assets/css/style.p1363.5.10.0.1669847362.css'
import '../src/assets/css/style.p1365.5.10.0.1669847362.css'
import '../src/assets/css/style.p1367.5.10.0.1669847362.css'
import '../src/assets/css/style.p1369.5.10.0.1669847362.css'
import '../src/assets/css/style.p1523.5.10.0.1669847362.css'
import '../src/assets/css/style.p1525.5.10.0.1669847362.css'
import '../src/assets/css/style.p1527.5.10.0.1669847362.css'
import '../src/assets/css/style.p1529.5.10.0.1669847362.css'
import '../src/assets/css/style.p1531.5.10.0.1669847362.css'
import '../src/assets/css/style.p1543.5.10.0.1669847362.css'
import '../src/assets/css/style.p1574.5.10.0.1669847362.css'
import '../src/assets/css/style.p1734.5.10.0.1669847362.css'
import '../src/assets/css/style.p961.5.10.0.1669847362.css'
import '../src/assets/css/style.p964.5.10.0.1669847362.css'
import '../src/assets/css/style.p966.5.10.0.1669847362.css'
import '../src/assets/css/style.p968.5.10.0.1669847362.css'
import '../src/assets/css/wpforms-full.min.css'
import "swiper/swiper.min.css"
router.afterEach((to,from,next)=>{
  window.scrollTo(0,0);
})







